<h1 mat-dialog-title>
    {{ form.value._id ? 'Atualizar tipo de título' : 'Cadastrar tipo de título' }}
</h1>
<form autocomplete="off"
      [formGroup]="form">
    <mat-dialog-content>

        <!-- description -->
        <mat-form-field class="col-12">
            <mat-label>Descrição</mat-label>
            <input matInput
                   formControlName="description"
                   placeholder="Descrição"
                   required>
            <mat-error *ngIf="form.get('description').hasError('required')">
                {{ 'VALIDATION.REQUIRED_FIELD' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- typeForAdvance -->
        <mat-form-field class="col-12"
                        title="O outro tipo de título, que será utilizado para criar o título que fica COM saldo ao fazer um adiantamento">
            <mat-label>Tipo de título para adiantamento</mat-label>
            <mat-select formControlName="typeForAdvance">
                @for (ttype of filteredTypes; track $index) {
                <mat-option [value]="ttype._id">
                    {{ ttype.description }}
                </mat-option>
                }
                <mat-option>-- Nenhum</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- isPay -->
        <div class="mat-form-field col-12 radios">
            <mat-label>Pagar ou receber</mat-label>
            <br>
            <mat-radio-group required
                             formControlName="isPay">
                <mat-radio-button [value]="true"
                                  color="primary">Pagar</mat-radio-button>
                <mat-radio-button [value]="false"
                                  color="primary">Receber</mat-radio-button>
            </mat-radio-group>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="close()">
            {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label=save
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid">
            {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
    </mat-dialog-actions>
</form>